import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { AuthGuard } from './auth/auth.guard';

import { PublicationsComponent } from './contents/publications/publications.component';
import { CircuitsComponent } from './contents/circuits/circuits.component';
import { PointsComponent } from './contents/points/points.component';
import { TraductionsComponent } from './contents/traductions/traductions.component';

import { StepOneComponent } from './contents/create-circuit/step-one/step-one.component';
import { StepTwoComponent } from './contents/create-circuit/step-two/step-two.component';
import { StepThreeComponent } from './contents/create-circuit/step-three/step-three.component';
import { StepForComponent } from './contents/create-circuit/step-for/step-for.component';

import { StepOnePublicationComponent } from './contents/create-publication/step-one-publication/step-one-publication.component';
import { StepTwoPublicationComponent } from './contents/create-publication/step-two-publication/step-two-publication.component';

import { LanguesComponent } from './my-app/langues/langues.component';
import { PersonnalisationComponent } from './my-app/personnalisation/personnalisation.component';
import { UsersComponent } from './my-app/users/users.component';
import { EditPointComponent } from './contents/points/edit-point/edit-point.component';
import { CreatePointComponent } from './contents/points/create-point/create-point.component';
import { StatsComponent } from './my-app/stats/stats.component';
import { SingleStatsComponent } from './my-app/single-stats/single-stats.component';
import { AccountsComponent } from './my-app/accounts/accounts.component';

const routes: Routes = [
  { path: '', redirectTo: 'publications', pathMatch: 'full' },
  { path: 'circuits', component: CircuitsComponent, canActivate: [AuthGuard]},
  { path: 'publications', component: PublicationsComponent, canActivate: [AuthGuard]},
  { path: 'create-publication-1', component: StepOnePublicationComponent, canActivate: [AuthGuard]},
  { path: 'create-publication-2', component: StepTwoPublicationComponent, canActivate: [AuthGuard]},
  { path: 'points', component: PointsComponent, canActivate: [AuthGuard]},
  { path: 'edit-point/:type/:id', component: EditPointComponent, canActivate: [AuthGuard]},
  { path: 'add-point', component: CreatePointComponent, canActivate: [AuthGuard]},
  { path: 'traductions', component: TraductionsComponent, canActivate: [AuthGuard]},
  { path: 'langues', component: LanguesComponent, canActivate: [AuthGuard]},
  { path: 'personnalisation', component: PersonnalisationComponent, canActivate: [AuthGuard]},
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
  { path: 'accounts', component: AccountsComponent, canActivate: [AuthGuard]},
  { path: 'statistiques', component: StatsComponent, canActivate: [AuthGuard]},
  { path: 'statistiques/:appVue', component: SingleStatsComponent, canActivate: [AuthGuard]},
  { path: 'create-circuit-1', component: StepOneComponent, canActivate: [AuthGuard]},
  { path: 'create-circuit-2', component: StepTwoComponent, canActivate: [AuthGuard]},
  { path: 'create-circuit-3', component: StepThreeComponent, canActivate: [AuthGuard]},
  { path: 'create-circuit-4', component: StepForComponent, canActivate: [AuthGuard]},
  { path: 'login', component: AuthComponent },
  { path: '**', redirectTo: 'publications'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
