import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-personnalisation-generale',
  templateUrl: './personnalisation-generale.component.html',
  styleUrls: ['./personnalisation-generale.component.scss']
})
export class PersonnalisationGeneraleComponent implements OnInit {

  @Input() currentConfig: any;
  @Output() updateConfig = new EventEmitter<any>();

  configID: any;

  appTitle: string;
  metaDescription: string;
  colors: any = [
    {
      title: 'Couleur principale',
      id: 'colorp',
      value: 'undefined'
    },
    {
      title: 'Couleur alternative',
      id: 'colora',
      value: 'undefined'
    },
    {
      title: 'Couleur texte principal',
      id: 'color_txtp',
      value: 'undefined'
    },
    {
      title: 'Couleur texte alternatif',
      id: 'color_txta',
      value: 'undefined'
    },
    {
      title: 'Couleur des liens',
      id: 'color_link',
      value: 'undefined'
    },
    {
      title: 'Couleur du navigateur',
      id: 'color_nav',
      value: 'undefined'
    }
  ];

  templateArticles: any;
  newBandeauArticles: File[] = [];
  newLogoArticles: File[] = [];

  templateTrajets: any;
  newBandeauTrajets: File[] = [];
  newLogoTrajets: File[] = [];

  constructor(private apiService: ApiService, private toastr: ToastrService) { }

  ngOnInit(): void {
    if (this.currentConfig) {
      this.configID = this.currentConfig.id;
      this.appTitle = this.currentConfig.apptitle;
      this.metaDescription = this.currentConfig.description;
      this.colors[0].value = this.currentConfig.colorp;
      this.colors[1].value = this.currentConfig.colora;
      this.colors[2].value = this.currentConfig.color_txtp;
      this.colors[3].value = this.currentConfig.color_txta;
      this.colors[4].value = this.currentConfig.color_link;
      this.colors[5].value = this.currentConfig.color_nav; 
    }
    this.getTemplates();
  }

  onSave() {
    let colorp = this.colors[0].value;
    let colora = this.colors[1].value;
    let color_txtp = this.colors[2].value;
    let color_txta = this.colors[3].value;
    let color_link = this.colors[4].value;
    let color_nav = this.colors[5].value;
    this.apiService.addConfig(this.appTitle, {config_id: this.configID, description: this.metaDescription, colorp: colorp, colora: colora, color_txtp: color_txtp, color_txta: color_txta, color_link: color_link, color_nav: color_nav}).subscribe(
      data => {
        this.updateConfig.next(data.data.config_id);
        this.toastr.success('Configuration sauvegardée', '', {
          timeOut: 2000,
        });
      },
      err => {
        console.log(err);
      }
    );
    if (this.templateArticles) {
      this.apiService.editArticlesTemplate(this.templateArticles.id, { title: this.templateArticles.title, position_title: this.templateArticles.position_title, subtitle: this.templateArticles.subtitle, bandeau: this.newBandeauArticles[0], logo: this.newLogoArticles[0], position_logo: this.templateArticles.position_logo}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
    if (this.templateTrajets) {
      this.apiService.editTrajetsTemplate(this.templateTrajets.id, { title: this.templateTrajets.title, position_title: this.templateTrajets.position_title, subtitle: this.templateTrajets.subtitle, bandeau: this.newBandeauTrajets[0], logo: this.newLogoTrajets[0], position_logo: this.templateTrajets.position_logo}).subscribe(
        data => {
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  getTemplates() {
    let lang = JSON.parse(localStorage.getItem('currentLang')) || 'fr_FR';    
    this.apiService.getArticlesTemplate().subscribe(
      data => {
        this.templateArticles = data.data;
        this.templateArticles = this.templateArticles.find(x => x.lang == lang);
        if (this.templateArticles.medias.length) {
          this.templateArticles.medias = this.templateArticles.medias.reverse();
          let bandeauImg = this.templateArticles.medias.find(x => x.name == 'bandeau');
          let logoImg = this.templateArticles.medias.find(x => x.name == 'logo');
          if (bandeauImg) {
            var blobBandeau = null;
            var xhrBandeau = new XMLHttpRequest();
            xhrBandeau.open("GET", "https://cors.belooga-inc.ca/raw?url=" + bandeauImg.url);
            xhrBandeau.responseType = "blob";
            xhrBandeau.onload = () => 
            {
              blobBandeau = xhrBandeau.response;
              var file = new File([blobBandeau], bandeauImg.file_name, {type: bandeauImg.mime_type, lastModified:new Date().getTime()});
              this.newBandeauArticles.push(file);
            }
            xhrBandeau.send();
          }
          if (logoImg) {
            var blobLogo = null;
            var xhrLogo = new XMLHttpRequest();
            xhrLogo.open("GET", "https://cors.belooga-inc.ca/raw?url=" + logoImg.url);
            xhrLogo.responseType = "blob";
            xhrLogo.onload = () => 
            {
              blobLogo = xhrLogo.response;
              var file = new File([blobLogo], logoImg.file_name, {type: logoImg.mime_type, lastModified:new Date().getTime()});
              this.newLogoArticles.push(file);
            }
            xhrLogo.send();
          }
        }
      },
      err => {
        console.log(err);
      }
    );
    this.apiService.getTrajetsTemplate().subscribe(
      data => {
        this.templateTrajets = data.data;
        this.templateTrajets = this.templateTrajets.find(x => x.lang == lang);
        if (this.templateTrajets.medias.length) {
          this.templateTrajets.medias = this.templateTrajets.medias.reverse();
          let bandeauImg = this.templateTrajets.medias.find(x => x.name == 'bandeau');
          let logoImg = this.templateTrajets.medias.find(x => x.name == 'logo');
          if (bandeauImg) {
            var blobBandeau = null;
            var xhrBandeau = new XMLHttpRequest();
            xhrBandeau.open("GET", "https://cors.belooga-inc.ca/raw?url=" + bandeauImg.url);
            xhrBandeau.responseType = "blob";
            xhrBandeau.onload = () => 
            {
              blobBandeau = xhrBandeau.response;
              var file = new File([blobBandeau], bandeauImg.file_name, {type: bandeauImg.mime_type, lastModified:new Date().getTime()});
              this.newBandeauTrajets.push(file);
            }
            xhrBandeau.send();
          }
          if (logoImg) {
            var blobLogo = null;
            var xhrLogo = new XMLHttpRequest();
            xhrLogo.open("GET", "https://cors.belooga-inc.ca/raw?url=" + logoImg.url);
            xhrLogo.responseType = "blob";
            xhrLogo.onload = () => 
            {
              blobLogo = xhrLogo.response;
              var file = new File([blobLogo], logoImg.file_name, {type: logoImg.mime_type, lastModified:new Date().getTime()});
              this.newLogoTrajets.push(file);
            }
            xhrLogo.send();
          }
        }
      },
      err => {
        console.log(err);
      }
    );
  }

  onAddTemplate(type) {
    if (type == 1) {
      this.apiService.addArticlesTemplate(2, 'Bandeau articles').subscribe(
        data => {
          this.getTemplates();
        },
        err => {
          console.log(err);
        }
      );
    } else if (type == 2) {
      this.apiService.addTrajetsTemplate(2, 'Bandeau circuits').subscribe(
        data => {
          this.getTemplates();
        },
        err => {
          console.log(err);
        }
      );
    }
  }

  selectNewBandeauTrajets(event) {
    if (this.newBandeauTrajets.length) {
      this.newBandeauTrajets.length = 0;
    }
    this.newBandeauTrajets.push(event.addedFiles[0]);
  }

  removeNewBandeauTrajets(event) {
    this.newBandeauTrajets.splice(this.newBandeauTrajets.indexOf(event), 1);
  }

  selectNewLogoTrajets(event) {
    if (this.newLogoTrajets.length) {
      this.newLogoTrajets.length = 0;
    }
    this.newLogoTrajets.push(event.addedFiles[0]);
  }

  removeNewLogoTrajets(event) {
    this.newLogoTrajets.splice(this.newLogoTrajets.indexOf(event), 1);
  }


  selectNewBandeauArticles(event) {
    if (this.newBandeauArticles.length) {
      this.newBandeauArticles.length = 0;
    }
    this.newBandeauArticles.push(event.addedFiles[0]);
  }

  removeNewBandeauArticles(event) {
    this.newBandeauArticles.splice(this.newBandeauArticles.indexOf(event), 1);
  }

  selectNewLogoArticles(event) {
    if (this.newLogoArticles.length) {
      this.newLogoArticles.length = 0;
    }
    this.newLogoArticles.push(event.addedFiles[0]);
  }

  removeNewLogoArticles(event) {
    this.newLogoArticles.splice(this.newLogoArticles.indexOf(event), 1);
  }

}
