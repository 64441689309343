<div class="ifAuthenticated" *ngIf="isAuthenticated">
  <ng-container *ngIf="!menuMobile">
    <div [className]="menuIsActive ? 'menu show' : 'menu hide'">
      <div [className]="canHide ? 'hideMenu show' : 'hideMenu hide'" (click)="hideMenu()">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 443.52 443.52" style="enable-background:new 0 0 443.52 443.52;" xml:space="preserve">
          <g>
            <g>
              <path d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
                L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
                C342.889,227.058,342.889,216.255,336.226,209.591z"/>
            </g>
          </g>
        </svg>
      </div>
      <div class="langs" *ngIf="hasLangs">
        <div class="lang" *ngFor="let langue of langs" [class.active]="langue.code_lang == currentLang">
          <div class="flag fr" *ngIf="langue.code_lang == 'fr_FR'" (click)="switchLang(langue)">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <path style="fill:#41479B;" d="M170.667,423.721H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.1c0-4.875,3.953-8.828,8.828-8.828
                h161.839V423.721z"/>
              <rect x="170.67" y="88.277" style="fill:#F5F5F5;" width="170.67" height="335.45"/>
              <path style="fill:#FF4B55;" d="M503.172,423.721H341.333V88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.793
                C512,419.77,508.047,423.721,503.172,423.721z"/>
            </svg>
          </div>
          <div class="flag en" *ngIf="langue.code_lang == 'en_GB'" (click)="switchLang(langue)">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
              <path style="fill:#41479B;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
                h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.772,508.047,423.725,503.172,423.725z"/>
              <path style="fill:#F5F5F5;" d="M512,97.104c0-4.875-3.953-8.828-8.828-8.828h-39.495l-163.54,107.147V88.276h-88.276v107.147
                L48.322,88.276H8.828C3.953,88.276,0,92.229,0,97.104v22.831l140.309,91.927H0v88.276h140.309L0,392.066v22.831
                c0,4.875,3.953,8.828,8.828,8.828h39.495l163.54-107.147v107.147h88.276V316.578l163.54,107.147h39.495
                c4.875,0,8.828-3.953,8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512,119.935V97.104z"/>
              <g>
                <polygon style="fill:#FF4B55;" points="512,229.518 282.483,229.518 282.483,88.276 229.517,88.276 229.517,229.518 0,229.518 
                  0,282.483 229.517,282.483 229.517,423.725 282.483,423.725 282.483,282.483 512,282.483 	"/>
                <path style="fill:#FF4B55;" d="M178.948,300.138L0.25,416.135c0.625,4.263,4.14,7.59,8.577,7.59h12.159l190.39-123.586h-32.428
                  V300.138z"/>
                <path style="fill:#FF4B55;" d="M346.388,300.138H313.96l190.113,123.404c4.431-0.472,7.928-4.09,7.928-8.646v-7.258
                  L346.388,300.138z"/>
                <path style="fill:#FF4B55;" d="M0,106.849l161.779,105.014h32.428L5.143,89.137C2.123,90.54,0,93.555,0,97.104V106.849z"/>
                <path style="fill:#FF4B55;" d="M332.566,211.863L511.693,95.586c-0.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138,211.863
                  H332.566z"/>
              </g>
            </svg>
          </div>
          <!-- <div class="flag de" *ngIf="langue.code_lang == 'de_DE'" (click)="switchLang(langue)">
            <svg xmlns="http://www.w3.org/2000/svg" width="1000" height="600" viewBox="0 0 5 3">
              <rect id="black_stripe" width="5" height="3" y="0" x="0" fill="#000"/>
              <rect id="red_stripe" width="5" height="2" y="1" x="0" fill="#D00"/>
              <rect id="gold_stripe" width="5" height="1" y="2" x="0" fill="#FFCE00"/>
            </svg> 
          </div> -->
        </div>
      </div>
      <!-- <div class="title" routerLink="/">
        Tableau de bord
      </div> -->
      <div class="sidebar-menu">
        <div class="contenus">
          <div class="title">
            Mes contenus
          </div>
          <ul>
            <li>
              <a style="cursor:pointer;" routerLink="/publications" routerLinkActive="active">
                Publications
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/circuits" routerLinkActive="active">
                Circuits
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/points" routerLinkActive="active">
                Points
              </a>
            </li>
          </ul>
        </div>
        <div class="app">
          <div class="title">
            Mon app
          </div>
          <ul>
            <li>
              <a style="cursor:pointer;" routerLink="/langues" routerLinkActive="active">
                Langues
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/traductions" routerLinkActive="active">
                Traductions
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/personnalisation" routerLinkActive="active">
                Personnalisation
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/users" routerLinkActive="active">
                Utilisateurs
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/accounts" routerLinkActive="active">
                Utilisateurs public
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/statistiques" routerLinkActive="active">
                Statistiques
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div [className]="menuIsActive ? 'logout show' : 'logout hide'" (click)="onLogout()">
      <svg height="512pt" viewBox="0 0 512.016 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m496 240.007812h-202.667969c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h202.667969c8.832031 0 16 7.167969 16 16 0 8.832032-7.167969 16-16 16zm0 0"/><path d="m416 320.007812c-4.097656 0-8.191406-1.558593-11.308594-4.691406-6.25-6.253906-6.25-16.386718 0-22.636718l68.695313-68.691407-68.695313-68.695312c-6.25-6.25-6.25-16.382813 0-22.632813 6.253906-6.253906 16.386719-6.253906 22.636719 0l80 80c6.25 6.25 6.25 16.382813 0 22.632813l-80 80c-3.136719 3.15625-7.230469 4.714843-11.328125 4.714843zm0 0"/><path d="m170.667969 512.007812c-4.566407 0-8.898438-.640624-13.226563-1.984374l-128.386718-42.773438c-17.46875-6.101562-29.054688-22.378906-29.054688-40.574219v-384c0-23.53125 19.136719-42.6679685 42.667969-42.6679685 4.5625 0 8.894531.6406255 13.226562 1.9843755l128.382813 42.773437c17.472656 6.101563 29.054687 22.378906 29.054687 40.574219v384c0 23.53125-19.132812 42.667968-42.664062 42.667968zm-128-480c-5.867188 0-10.667969 4.800782-10.667969 10.667969v384c0 4.542969 3.050781 8.765625 7.402344 10.28125l127.785156 42.582031c.917969.296876 2.113281.46875 3.480469.46875 5.867187 0 10.664062-4.800781 10.664062-10.667968v-384c0-4.542969-3.050781-8.765625-7.402343-10.28125l-127.785157-42.582032c-.917969-.296874-2.113281-.46875-3.476562-.46875zm0 0"/><path d="m325.332031 170.675781c-8.832031 0-16-7.167969-16-16v-96c0-14.699219-11.964843-26.667969-26.664062-26.667969h-240c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-15.9999995 16-15.9999995h240c32.363281 0 58.664062 26.3046875 58.664062 58.6679685v96c0 8.832031-7.167969 16-16 16zm0 0"/><path d="m282.667969 448.007812h-85.335938c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h85.335938c14.699219 0 26.664062-11.96875 26.664062-26.667968v-96c0-8.832032 7.167969-16 16-16s16 7.167968 16 16v96c0 32.363281-26.300781 58.667968-58.664062 58.667968zm0 0"/></svg>
    </div>
    <div [className]="menuIsActive ? 'deploy hide' : 'deploy show'" (click)="deployMenu()">
      <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 443.52 443.52" style="enable-background:new 0 0 443.52 443.52;" xml:space="preserve">
        <g>
          <g>
            <path d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
              L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
              C342.889,227.058,342.889,216.255,336.226,209.591z"/>
          </g>
        </g>
      </svg>
    </div>
  </ng-container>
  <ng-container *ngIf="menuMobile">
    <div [className]="editing ? 'menu mobile editing' : 'menu mobile'">
      <div class="langs" *ngIf="hasLangs && !editing">
        <div class="lang" *ngFor="let langue of langs" [class.active]="langue.code_lang == currentLang">
          <div class="flag fr" *ngIf="langue.code_lang == 'fr_FR'" (click)="switchLang(langue)">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
              <path style="fill:#41479B;" d="M170.667,423.721H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.1c0-4.875,3.953-8.828,8.828-8.828
                h161.839V423.721z"/>
              <rect x="170.67" y="88.277" style="fill:#F5F5F5;" width="170.67" height="335.45"/>
              <path style="fill:#FF4B55;" d="M503.172,423.721H341.333V88.273h161.839c4.875,0,8.828,3.953,8.828,8.828v317.793
                C512,419.77,508.047,423.721,503.172,423.721z"/>
            </svg>
          </div>
          <div class="flag en" *ngIf="langue.code_lang == 'en_GB'" (click)="switchLang(langue)">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 512.002 512.002" style="enable-background:new 0 0 512.002 512.002;" xml:space="preserve">
              <path style="fill:#41479B;" d="M503.172,423.725H8.828c-4.875,0-8.828-3.953-8.828-8.828V97.104c0-4.875,3.953-8.828,8.828-8.828
                h494.345c4.875,0,8.828,3.953,8.828,8.828v317.793C512,419.772,508.047,423.725,503.172,423.725z"/>
              <path style="fill:#F5F5F5;" d="M512,97.104c0-4.875-3.953-8.828-8.828-8.828h-39.495l-163.54,107.147V88.276h-88.276v107.147
                L48.322,88.276H8.828C3.953,88.276,0,92.229,0,97.104v22.831l140.309,91.927H0v88.276h140.309L0,392.066v22.831
                c0,4.875,3.953,8.828,8.828,8.828h39.495l163.54-107.147v107.147h88.276V316.578l163.54,107.147h39.495
                c4.875,0,8.828-3.953,8.828-8.828v-22.831l-140.309-91.927H512v-88.276H371.691L512,119.935V97.104z"/>
              <g>
                <polygon style="fill:#FF4B55;" points="512,229.518 282.483,229.518 282.483,88.276 229.517,88.276 229.517,229.518 0,229.518 
                  0,282.483 229.517,282.483 229.517,423.725 282.483,423.725 282.483,282.483 512,282.483 	"/>
                <path style="fill:#FF4B55;" d="M178.948,300.138L0.25,416.135c0.625,4.263,4.14,7.59,8.577,7.59h12.159l190.39-123.586h-32.428
                  V300.138z"/>
                <path style="fill:#FF4B55;" d="M346.388,300.138H313.96l190.113,123.404c4.431-0.472,7.928-4.09,7.928-8.646v-7.258
                  L346.388,300.138z"/>
                <path style="fill:#FF4B55;" d="M0,106.849l161.779,105.014h32.428L5.143,89.137C2.123,90.54,0,93.555,0,97.104V106.849z"/>
                <path style="fill:#FF4B55;" d="M332.566,211.863L511.693,95.586c-0.744-4.122-4.184-7.309-8.521-7.309h-12.647L300.138,211.863
                  H332.566z"/>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div class="sidebar-menu" *ngIf="!editing">
        <div class="contenus">
          <!-- <div class="title">
            Mes contenus
          </div> -->
          <ul>
            <li>
              <a style="cursor:pointer;" routerLink="/publications" routerLinkActive="active">
                Publications
              </a>
            </li>
            <!-- <li>
              <a style="cursor:pointer;" routerLink="/circuits" routerLinkActive="active">
                Circuits
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/points" routerLinkActive="active">
                Points
              </a>
            </li> -->
          </ul>
        </div>
        <!-- <div class="app">
          <div class="title">
            Mon app
          </div>
          <ul>
            <li>
              <a style="cursor:pointer;" routerLink="/langues" routerLinkActive="active">
                Langues
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/traductions" routerLinkActive="active">
                Traductions
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/personnalisation" routerLinkActive="active">
                Personnalisation
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/users" routerLinkActive="active">
                Utilisateurs
              </a>
            </li>
            <li>
              <a style="cursor:pointer;" routerLink="/statistiques" routerLinkActive="active">
                Statistiques
              </a>
            </li>
          </ul>
        </div> -->
      </div>
      <div class="return" *ngIf="editing">
        <a style="cursor:pointer;" routerLink="/publications" routerLinkActive="active">
          <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 443.52 443.52" style="enable-background:new 0 0 443.52 443.52;" xml:space="preserve">
            <g>
              <g>
                <path d="M336.226,209.591l-204.8-204.8c-6.78-6.548-17.584-6.36-24.132,0.42c-6.388,6.614-6.388,17.099,0,23.712l192.734,192.734
                  L107.294,414.391c-6.663,6.664-6.663,17.468,0,24.132c6.665,6.663,17.468,6.663,24.132,0l204.8-204.8
                  C342.889,227.058,342.889,216.255,336.226,209.591z"/>
              </g>
            </g>
          </svg>
        </a>
      </div>
      <div class="logout show" (click)="onLogout()" *ngIf="!editing">
        <svg height="512pt" viewBox="0 0 512.016 512" width="512pt" xmlns="http://www.w3.org/2000/svg"><path d="m496 240.007812h-202.667969c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h202.667969c8.832031 0 16 7.167969 16 16 0 8.832032-7.167969 16-16 16zm0 0"/><path d="m416 320.007812c-4.097656 0-8.191406-1.558593-11.308594-4.691406-6.25-6.253906-6.25-16.386718 0-22.636718l68.695313-68.691407-68.695313-68.695312c-6.25-6.25-6.25-16.382813 0-22.632813 6.253906-6.253906 16.386719-6.253906 22.636719 0l80 80c6.25 6.25 6.25 16.382813 0 22.632813l-80 80c-3.136719 3.15625-7.230469 4.714843-11.328125 4.714843zm0 0"/><path d="m170.667969 512.007812c-4.566407 0-8.898438-.640624-13.226563-1.984374l-128.386718-42.773438c-17.46875-6.101562-29.054688-22.378906-29.054688-40.574219v-384c0-23.53125 19.136719-42.6679685 42.667969-42.6679685 4.5625 0 8.894531.6406255 13.226562 1.9843755l128.382813 42.773437c17.472656 6.101563 29.054687 22.378906 29.054687 40.574219v384c0 23.53125-19.132812 42.667968-42.664062 42.667968zm-128-480c-5.867188 0-10.667969 4.800782-10.667969 10.667969v384c0 4.542969 3.050781 8.765625 7.402344 10.28125l127.785156 42.582031c.917969.296876 2.113281.46875 3.480469.46875 5.867187 0 10.664062-4.800781 10.664062-10.667968v-384c0-4.542969-3.050781-8.765625-7.402343-10.28125l-127.785157-42.582032c-.917969-.296874-2.113281-.46875-3.476562-.46875zm0 0"/><path d="m325.332031 170.675781c-8.832031 0-16-7.167969-16-16v-96c0-14.699219-11.964843-26.667969-26.664062-26.667969h-240c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-15.9999995 16-15.9999995h240c32.363281 0 58.664062 26.3046875 58.664062 58.6679685v96c0 8.832031-7.167969 16-16 16zm0 0"/><path d="m282.667969 448.007812h-85.335938c-8.832031 0-16-7.167968-16-16 0-8.832031 7.167969-16 16-16h85.335938c14.699219 0 26.664062-11.96875 26.664062-26.667968v-96c0-8.832032 7.167969-16 16-16s16 7.167968 16 16v96c0 32.363281-26.300781 58.667968-58.664062 58.667968zm0 0"/></svg>
      </div>
    </div>
  </ng-container>
</div>
