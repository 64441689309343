<div class="page">
  <div class="loader" *ngIf="isLoading">
    <app-loading-spinner></app-loading-spinner>
  </div>
  <div class="header-page">
    <div class="title" *ngIf="vue">
      <div class="back" routerLink="/statistiques">
        Voir les statistiques générales
      </div>
      <div class="vue">
        {{ vue.vue_title }}
      </div>
    </div>
    <div class="filters">
      <div class="title">
        Filtrer par dates
      </div>
      <div class="date-time">
        <div class="start">
          <ngx-datepicker [(ngModel)]="datePickerStart" [options]="optionsDatePicker" (ngModelChange)="setStartDate($event)"></ngx-datepicker>
        </div>
        <div class="end">
          <ngx-datepicker [(ngModel)]="datePickerEnd" [options]="optionsDatePicker" (ngModelChange)="setEndDate($event)"></ngx-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="contenu-page">
    <div class="stats">
      <div class="global" *ngIf="vue">
        <div class="bloc vues">
          <div class="title">
            Total vues
          </div>
          <div class="data">
            {{ vue.total_vues | number : '1.2-2' }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.start_trajet">
          <div class="title">
            Nombre de démarrage parcours
          </div>
          <div class="data">
            {{ trajetStats.start_trajet }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.average_exit_step">
          <div class="title">
            Étape de sortie moyenne des utilisateurs
          </div>
          <div class="data">
            {{ trajetStats.average_exit_step }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.average_time">
          <div class="title">
            Durée d'utilisation moyenne
          </div>
          <div class="data">
            {{ trajetStats.average_time }} minutes
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.end_trajet_minus2">
          <div class="title">
            Parcours finalisés
          </div>
          <div class="data">
            {{ trajetStats.end_trajet_minus2 }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.end_trajet">
          <div class="title">
            Parcours réussis
          </div>
          <div class="data">
            {{ trajetStats.end_trajet }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.avg_notes">
          <div class="title">
            Note moyenne
          </div>
          <div class="data">
            {{ trajetStats.avg_notes | number : '1.2-2' }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.good_answer_count">
          <div class="title">
            Nombre de bonnes réponses
          </div>
          <div class="data">
            {{ trajetStats.good_answer_count }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.wrong_answer_count">
          <div class="title">
            Nombre de mauvaises réponses
          </div>
          <div class="data">
            {{ trajetStats.wrong_answer_count }}
          </div>
        </div>
        <div class="bloc vues" *ngIf="trajetStats && trajetStats.avg_scores">
          <div class="title">
            Nombre de points moyen
          </div>
          <div class="data">
            {{ trajetStats.avg_scores | number : '1.2-2' }}
          </div>
        </div>
      </div>
      <div class="graphs">
        <div class="graph vues" *ngIf="vuesChartData">
          <div class="title">
            Vues
          </div>
          <div class="data">
            <div class="chart-wrapper">
              <canvas baseChart 
                [datasets]="vuesChartData" 
                [labels]="vuesChartLabels" 
                [options]="lineChartOptions"
                [colors]="lineChartColors" 
                [legend]="lineChartLegend" 
                [chartType]="lineChartType" 
                [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="users-stats" *ngIf="trajetStats && trajetStats.accounts && trajetStats.accounts.length">
      <div class="title">
        Utilisateurs ayant effectué le parcours
      </div>
      <div class="users">
        <div class="user" *ngFor="let user of trajetStats.accounts">
          <div class="content">
            <div class="names">
              <span class="name">
                {{ user.lastname }}
              </span>
              <span class="firstname">
                {{ user.firstname }}
              </span>
            </div>
            <div class="mail">
              {{ user.email }}
            </div>
            <div class="answers">
              Bonnes réponses : {{ user.total_goodanswers }} / {{ user.total_questions }}
            </div>
            <div class="points">
              Points : {{ user.scores_sum }} <span *ngIf="user.totaux_quizz && user.totaux_quizz.total_score">/ {{ user.totaux_quizz.total_score }}</span>
            </div>
            <div class="keys">
              Clés : {{ user.keys_sum }} <span *ngIf="user.totaux_quizz && user.totaux_quizz.total_key">/ {{ user.totaux_quizz.total_key }}</span>
            </div>
            <div class="letters">
              Lettres : {{ user.letters_sum }} <span *ngIf="user.totaux_quizz && user.totaux_quizz.total_letters">/ {{ user.totaux_quizz.total_letters }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>